import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Input, Spinner } from '../components/atomics'
import gcLogoSq from "../assets/gc_logo_square_white.svg";
import { resetPassword } from '../services/AuthSettingService';
import toast from 'react-hot-toast';
import HandleUIError from '../utils/HandleUIError';
import { useDispatch, useSelector } from 'react-redux';
import { clearVerification } from '../redux/reducers/resetPasswordReducer';
import { authenticate } from '../services/SecurityService';

const ForgetPassword = () => {
    const [password, setpassword] = useState()
    const [confirmPassword, setConfirmPassword] = useState()
    const [loading, setLoading] = useState()

    const email = useSelector((state) => state.resetPass?.email)
    const otp = useSelector((state) => state.resetPass?.otp)

    const dispatch = useDispatch()
    const history = useHistory()


    // const handleSubmit = () => {
    //     console.log(email)
    //     history.push('/new-password')

    // }

    const handleSubmit = async (e) => {
        e.preventDefault();
        // return history.push('/')
        if (password != confirmPassword) {
            toast.error("Password does not match");
            return
        }
        else if (password.length < 6) {
            toast.error("Password required minimun 6 alphabets");
            return
        }
        else {
            try {
                const res = await resetPassword({ email, otp, password });
                // setLoading(false)
                if (res?.data?.status === "success") {
                    try {
                        // setLoading(true);
                        await authenticate({ email, password });
                        return history.push('/')
                        dispatch(clearVerification())
                        // setLoading(false);

                    } catch (e) {
                        HandleUIError(e);
                        setLoading(false);
                    }
                }
                else if (res?.data?.status === "error") {
                    toast.error(res?.data?.message);
                }
            } catch (e) {
                setLoading(false);
                HandleUIError(e);
            }
        }


    };


    return (
        <div className="login-container">
            <div className="login-border p-4">
                <form className="">
                    <h1 className="text-center mb-4 text-color login-title">
                        <img src={gcLogoSq} alt="GuardianCall" className="img-fluid" />
                    </h1>
                    <div className="mb-3">
                        <p className="text-light mb-2">New Password</p>
                        <Input
                            type="password"
                            value={password}
                            onChange={(e) => setpassword(e.target.value)}
                            // onChange={changeEmail}
                            className="w-100  login-input"
                            placeholder='Enter your new password here'
                        />
                    </div>
                    <div className="mb-3">
                        <p className="text-light mb-2">Confirm Password</p>
                        <Input
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            // onChange={changeEmail}
                            className="w-100 login-input"
                            placeholder='Re-type your new password'
                        />
                    </div>

                    <Button
                        onClick={handleSubmit}
                        className="w-100 fs-4 sign-in-button"
                        disabled={loading}
                    >
                        {loading ? <Spinner className="ms-3" /> : "Submit"}
                    </Button>
                </form>
            </div>
        </div>
    );
}

export default ForgetPassword