import * as React from "react";

interface Props {
  isDrillMode: boolean;
}

const DrillModeIndicator: React.FC<Props> = ({ isDrillMode }) => {
  return <>{isDrillMode ? <div className="drill-mode-indicator" /> : null}</>;
};

export default DrillModeIndicator;
