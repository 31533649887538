import * as React from "react";
import "./Login.scss";
import { Input, Button, Spinner } from "../components/atomics";
import { authenticate } from "../services/SecurityService";
import { inputEvent } from "../utils/types";
import { validateEmail } from "../helpers/validations";
import HandleUIError from "../utils/HandleUIError";
import ApiErrorStatusCode from "../utils/ApiErrorStatusCode";

// images
import gcLogoSq from "../assets/gc_logo_square_white.svg";
import { Link } from "react-router-dom";

const Login: React.FC = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  function changeEmail(e: inputEvent) {
    setEmail(e.currentTarget.value);
  }

  function changePassword(e: inputEvent) {
    setPassword(e.currentTarget.value);
  }

  function onPressSignIn(e: React.FormEvent<HTMLButtonElement>) {
    e.preventDefault();
    if (!validateEmail(email)) {
      setError("Email is not valid");
      return;
    }
    signIn();
  }

  function errorManagement(errorCode: number) {
    if (errorCode === ApiErrorStatusCode.AuthenticationError) {
      setError("Invalid email or password");
    }
  }

  async function signIn() {
    try {
      setLoading(true);
      await authenticate({ email, password });
      setLoading(false);
    } catch (e) {
      HandleUIError(e, errorManagement);
      setLoading(false);
    }
  }

  return (
    <div className="login-container">
      <div className="login-border p-4">
        <form className="">
          <h1 className="text-center mb-4 text-color login-title">
            <img src={gcLogoSq} alt="GuardianCall" className="img-fluid" />
          </h1>
          <div className="mb-3">
            <p className="text-light mb-2">Enter your email</p>
            <Input
              type="email"
              onChange={changeEmail}
              className="w-100 "
            />
          </div>
          <div className="">
            <p className="text-light mb-2">Enter your password</p>
            <Input
              onChange={changePassword}
              type="password"
              className="w-100 border-0"
            />
            {error && <p className="text-danger">{error}</p>}
          </div>

          <div className="mb-3 text-end">
            <Link to={"/verify"} className="text-light ">Forgot password ?</Link>
          </div>
          <Button
            onClick={onPressSignIn}
            className="w-100 fs-6"
            disabled={loading}
          >
            {loading ? <Spinner className="ms-3" /> : "Sign In"}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Login;
