import * as React from "react";
import { OrganizationResponse } from "../../services/types";
import { DrillModeIndicator } from ".";
import { IconButton } from "../atomics";
import { cilPencil, cilTrash } from "@coreui/icons";
import { OrgSelected } from "../../utils/types";
import toast from "react-hot-toast";
import { deleteOrganization } from "../../services/OrganizationsServices";
import HandleUIError from "../../utils/HandleUIError";
import confirmationDialog from "../toast-dialogs/ConfirmationDialog";

interface Props {
  organization: OrganizationResponse;
  index?: any;
  selectOrg: (org: any) => void;
  handleStatus: (id: any, status: any) => void;
  handleDeleteOrganization: (id: any) => void;
  setPreviousAction: (val: boolean) => void;
  previousAction: boolean;
  loading: boolean;
  refreshOrganizations: () => Promise<void>;

}



const OrganizationRow: React.FC<Props> = ({ organization, index, selectOrg, handleStatus, handleDeleteOrganization, setPreviousAction,
  previousAction, loading, refreshOrganizations }) => {
  const { id, name, address, isDrillMode, countryCode, postalCode, stateCode, importTemplates,
    standard, custom, pre_store_message, active } =
    organization;

  const fullAddress = `${address ? address : ""}, ${postalCode ? postalCode : ""
    } ${stateCode ? stateCode : ""} ${countryCode ? countryCode : ""}`;

  const [isLoading, setIsLoading] = React.useState<boolean>(false);


  function onPressEdit() {
    selectOrg(organization);
  }

  const handleDelete = (id: any) => {
    // setIsLoading(true)
    handleDeleteOrganization(id)
    // setIsLoading(false)
  }

  const [isLoadingDelete, setIsLoadingDelete] = React.useState(false);


  async function deleteTemplate() {
    if (id) {
      setIsLoadingDelete(true);
      try {
        await deleteOrganization(id);
        await refreshOrganizations();
        setIsLoadingDelete(false);
      } catch (e) {
        setIsLoadingDelete(false);
        HandleUIError(e);
      }
    }
  }

  function onPressDelete() {
    confirmationDialog({
      title: "Are you sure you want to delete this template?",
      confirmAction: deleteTemplate,
    });
  }

  return (
    <div className="d-flex justify-content-between user-information-container">
      <div className="d-flex py-3  w-100">
        <p className="section-width-1 text-light ms-4">{index + 1}</p>
        <p className="section-width-2 text-light">{name}</p>
        <p className="section-width-3 text-light ms-2">{fullAddress}</p>
        <div className="section-width-4 ms-3">
          <DrillModeIndicator isDrillMode={isDrillMode} />
        </div>
        <p className="section-width-4 text-light ms-2">
          {active ? (
            <button onClick={() => { handleStatus(id, active) }} className="btn btn-success text-white small">
              Active
            </button>
          ) : (
            <button onClick={() => { handleStatus(id, active) }} className="btn btn-danger text-white small">
              Deactive
            </button>
          )}
        </p>
      </div>
      <div className="d-flex align-items-center m-0 me-3 ">
        <IconButton icon={cilPencil} onClick={onPressEdit} />


        {/* <div>
          {console.log("previousAction", previousAction)}
          {previousAction ? (
            <>
              <IconButton
                icon={cilTrash}
                size="lg"
                className="ms-1"
                // isLoading={isLoading}
                onClick={() => {
                  toast.error("Please confirm the previous action");
                }}
              />
            </>
          ) : (
            <IconButton
              icon={cilTrash}
              size="lg"
              className="ms-1"
              // isLoading={isLoading}
              onClick={() => {
                handleDeleteOrganization(id);
              }}
            />
          )}
        </div> */}

        <IconButton
          icon={cilTrash}
          onClick={onPressDelete}
          isLoading={isLoadingDelete}
          className="m-0 ms-3"
          size="lg"
        />

        {/* < IconButton
          icon={cilTrash}
          size="lg"
          className="ms-1"
          onClick={() => { handleDelete(id) }}
        /> */}

      </div>
    </div>
  );
};

export default OrganizationRow;
