import axiosConfig from "./axiosConfig";
import { AxiosResponse, AxiosError } from "axios";
import { store } from "../redux/store";
import { saveUser, removeUser } from "../redux/reducers/userReducer";
import ApiError from "../utils/ApiError";
import { AuthenticateData, AuthenticateResponse } from "./types";
import { getOrganizationInfo, getUserFullInfo } from "./loggedUserService";
import toast from "react-hot-toast";
import {
  clearOrganization,
  saveOrganization,
} from "../redux/reducers/organizationReducer";

async function getPermissionsRoleAndSaveUserData() {
  const res = await getUserFullInfo();
  console.log(res);
  if (res.isAdmin && res.isOrganizationManager && res.organizationId) {
    return true;
  } else {
    return false;
  }
}

export async function authenticate(
  loginData: AuthenticateData
): Promise<AuthenticateResponse> {
  try {
    const response = await axiosConfig.post<AxiosResponse>(
      "/authenticate",
      loginData
    );
    const authenticateData: AuthenticateResponse = response.data
      .data as AuthenticateResponse;
    const userData = {
      user: {
        id: authenticateData.id,
        email: authenticateData.email,
        isAdmin: null,
        isOrganizationManager: null,
      },
      tokens: {
        access: authenticateData.tokens.access,
        refresh: authenticateData.tokens.refresh,
      },
    };
    store.dispatch(saveUser(userData));
    const userAllowed = await getPermissionsRoleAndSaveUserData();
    if (!userAllowed) {
      toast.error("The entered user is not organizationManager");
      signOut();
    } else {
      const res = await getOrganizationInfo();
      store.dispatch(saveOrganization(res));
    }
    return authenticateData;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function signOut(): Promise<void> {
  try {
    store.dispatch(removeUser());
    store.dispatch(clearOrganization());
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}
