import * as React from "react";
import { Switch, Route } from "react-router-dom";

import { Organizations, PredefinedMessages } from "../Views";
import AppTourCategory from "../components/AppTourCategory";
import AppTourSection from "../components/AppTourSection";
import EditProfile from "../components/EditProfile";

const LoadRoutes: React.FC = () => {
  return (
    <Switch>
      {/* the main path must be last */}
      <Route path="/pre-defined-messages">
        <PredefinedMessages />
      </Route>
      <Route path="/category">
        <AppTourCategory />
      </Route>
      <Route path="/section">
        <AppTourSection />
      </Route>
      <Route path="/edit-profile">
        <EditProfile />
      </Route>
      <Route path="/">
        <Organizations />
      </Route>

    </Switch>
  );
};

export default LoadRoutes;
