import * as React from "react";
import "./Organizations.scss";
import {
  Button,
  IconButton, Input,
  PageContainer,
  Pagination,
} from "../../components/atomics";
import { deleteOrganization, getOrganizations, updateOrganizationStatus } from "../../services/OrganizationsServices";
import HandleUIError from "../../utils/HandleUIError";
import {
  OrganizationsList,
  NewOrganization,
} from "../../components/Organizations";
import { OrganizationResponse } from "../../services/types";
import CIcon from "@coreui/icons-react";
import { cilArrowLeft, cilPlus, cilPencil } from "@coreui/icons";
import { OrgSelected } from "../../utils/types";
import confirmationDialog from "../../components/toast-dialogs/ConfirmationDialog";

const Organizations: React.FC = () => {
  const [organizations, setOrganizations] = React.useState<
    OrganizationResponse[]
  >([]);
  const [numberOfPages, setNumberOfPages] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const MAX_SIZE_ORGANIZATIONS = 10;
  const [isLoading, setIsLoading] = React.useState(false);
  const [visibleNewOrganization, setVisibleNewOrganization] =
    React.useState(false);
  const [selectedOrg, setSelectedOrg] = React.useState<
    OrgSelected | null | undefined
  >(null);
  const [editOrganization, setEditOrganization] = React.useState(false);
  const [searchedData, setSearchedData] = React.useState("")
  const [loading, setLoading] = React.useState(false)
  const [results, setResults] = React.useState([])
  const [previousAction, setPreviousAction] = React.useState(false)


  const [order, setOrder] = React.useState("DESC")

  async function refreshOrganizations() {
    setIsLoading(true);
    try {
      const organizationsResponse = await getOrganizations(
        currentPage,
        MAX_SIZE_ORGANIZATIONS,
        order,
        searchedData
      );
      setOrganizations(organizationsResponse.data);
      setNumberOfPages(organizationsResponse.total / MAX_SIZE_ORGANIZATIONS);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      HandleUIError(e);
    }
  }

  React.useEffect(
    function () {
      refreshOrganizations();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage]
  );

  function closeModalNewOrganization() {
    setVisibleNewOrganization(false);
    setEditOrganization(false);
  }

  function openModalNewOrganization() {
    setVisibleNewOrganization(true);
  }

  function closeView() {
    setSelectedOrg(null)
  }

  function openEditOrganization() {
    setEditOrganization(true);
    setVisibleNewOrganization(true);
  }

  const handleChangeOrder = (e: any) => {
    setOrder(e.target.value)
  }

  const orderSorting = async (order: any) => {
    try {
      const res = await getOrganizations(
        currentPage,
        MAX_SIZE_ORGANIZATIONS,
        order,
        searchedData);
      // setLoading(false)
      console.log("data", res?.data);
      setOrganizations(res.data);
    } catch (e) {
      setLoading(false);
      HandleUIError(e);
    }
  }

  React.useEffect(() => {
    orderSorting(order)
  }, [order])


  const handleInputChange = (event: any) => {
    setSearchedData(event.target.value);
  };

  const search = async (searchedData: any) => {

    try {
      const res = await getOrganizations(
        currentPage,
        MAX_SIZE_ORGANIZATIONS,
        order,
        searchedData);
      // setLoading(false)
      console.log("data", res?.data);
      setOrganizations(res.data);
    } catch (e) {
      setLoading(false);
      HandleUIError(e);
    }

  }

  React.useEffect(() => {
    search(searchedData)

    // Fetch data only if query is not empty
    if (searchedData.trim()?.length > 2) {
      search(searchedData);
    } else {
      // Clear results if query is empty
      setResults([]);
    }
  }, [searchedData]
  );

  const handleStatus = async (id: any, status: any) => {
    // setCheckStatus(status)
    if (status === false) {
      setLoading(true);
      if (id) {
        try {
          await updateOrganizationStatus(id, true);
          await refreshOrganizations();
          setLoading(false);
        } catch (e) {
          setLoading(false);
          HandleUIError(e);
        }
      }
    } else if (status === true) {
      try {
        await updateOrganizationStatus(id, false);
        await refreshOrganizations();
        setLoading(false);
      } catch (e) {
        setLoading(false);
        HandleUIError(e);
      }
    }
  }

  const handleDeleteOrganization = async (id: any) => {
    setLoading(true);
    if (id) {
      try {
        await deleteOrganization(id);
        await refreshOrganizations();
        setLoading(false);
      } catch (e) {
        setLoading(false);
        HandleUIError(e);
      }
    }
  }

  // function handleDeleteOrganization(id: number) {
  //   setPreviousAction(true);
  //   setIsLoading(true);
  //   const confirmationDialogOptions = {
  //     title:
  //       "Are you sure you want to remove this user admin?",
  //     confirmAction: () => deleteOrganization(id),
  //     cancelAction: () => {
  //       setIsLoading(false)
  //       setPreviousAction(false)
  //     },
  //   };
  //   confirmationDialog(confirmationDialogOptions);
  //   setTimeout(() => {
  //     setIsLoading(false);
  //     setPreviousAction(false);
  //   }, 4000)
  // } 


  return (
    <>
      <div className="bg-main h-100 over-flow-auto">
        <PageContainer className="pb-3">
          {!selectedOrg ? (
            <div className="row justify-content-end align-items-center pb-2">
              <div className="col-lg-3">
                <select
                  onChange={handleChangeOrder}
                  value={order}
                  className="form-select input-select-custom"
                  id="exampleFormControlSelect1"
                >
                  <option value={"DESC"} selected={order === "DESC"}>Descending Order</option>
                  <option value={"ASC"} selected={order === "ASC"}>Ascending Order</option>
                </select>
              </div>
              <div className="col-lg-3">
                <Input
                  type="search"
                  className="w-100 small pe-2"
                  placeholder="Search By Name"
                  onChange={handleInputChange}
                />
              </div>
              <div
                className="add-icon-container mg-lt add-icon-staff"
                onClick={openModalNewOrganization}
              >
                <CIcon icon={cilPlus} size="xl" className="add-icon" />
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-between mb-2">
              <div className="d-flex justify-content-start">
                <IconButton
                  icon={cilArrowLeft}
                  size="xl"
                  onClick={closeView}
                  className="close-group-content-button mb-1"
                />
              </div>
              <div className="d-flex">
                <Button onClick={openEditOrganization}>
                  Edit organization
                  <CIcon className="ms-2" icon={cilPencil} />
                </Button>
              </div>
            </div>
          )}
          <OrganizationsList
            
            isLoading={isLoading}
            organizations={organizations}
            selectOrg={setSelectedOrg}
            selectedOrg={selectedOrg}
            handleStatus={handleStatus}
            handleDeleteOrganization={handleDeleteOrganization}
            setPreviousAction={setPreviousAction}
            previousAction={previousAction}
            loading={loading}
            refreshOrganizations={refreshOrganizations}

          />
          {!selectedOrg && (
            <div className="mt-4">
              {numberOfPages > 1 ? (
                <Pagination
                  numberOfPages={numberOfPages}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                />
              ) : (
                <></>
              )}
            </div>
          )}
        </PageContainer>
      </div>
      <NewOrganization
        visible={visibleNewOrganization}
        closeModal={closeModalNewOrganization}
        refreshOrganizations={refreshOrganizations}
        editMode={editOrganization}
        organizationId={selectedOrg?.id}
        organizationData={selectedOrg}
        closeView={closeView}
      />
    </>
  );
};

export default Organizations;
