import * as React from "react";
import "./NewTemplate.scss";

import { useReactMediaRecorder } from "react-media-recorder";

//components
import { cilTrash, cilMic } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { Button, IconButton, Spinner, Input } from "../atomics";
import {
  ChronometerAudioMessage,
  AudioPlayerCustom as AudioPlayer,
  DropZone,
} from ".";

//services
import { convertMp3ToArm, audioIsInFormat } from "../../utils/FormatConverters";
import {
  getAudioUploadUrl,
  uploadAudioToS3,
  createAudioMessageTemplate,
  sendAudioTemplate,
  sendAudioTemplateForMultipleDevices,
} from "../../services/messagesService";
import HandleUIError from "../../utils/HandleUIError";
import toast from "react-hot-toast";
import { TemplateMessageResponse } from "../../services/types";
import s3 from "../../config/aws-config";

interface Props {
  closeModal?: () => void;
  refreshTemplates?: () => Promise<void>;
  audioTemplate?: TemplateMessageResponse;
  deviceId?: number | number[] | null;
}

const AudioRecorder: React.FC<Props> = ({
  closeModal,
  refreshTemplates,
  audioTemplate,
  deviceId,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [name, setName] = React.useState("");
  const [file, setFile] = React.useState<any>();
  const [loadingSendTemplate, setLoadingSendTemplate] = React.useState(false);

  function onClose() {
    setName("");
    setFile("");

    closeModal && closeModal();
    // window.location.reload();
  }

  function onChangeFile(file: any) {
    setFile(file);
  }

  function onChangeName(e: any) {
    setName(e.target.value);
  }

  const handleFileUpload = async (file: any) => {
    return new Promise((resolve, reject) => {
      const params = {
        Bucket: "callguardian",
        Key: file.name,
        Body: file,
        ContentType: file.type,
        ContentDisposition: "inline",
      };

      s3.upload(params, (err: any, data: any) => {
        if (err) {
          console.error("Error uploading file:", err);
          reject(err);
        } else {
          console.log("File uploaded successfully:", data.Location);
          resolve(data.Location);
        }
      });
    });
  };

  async function saveAudioFile() {
    setLoading(true);
    if (file) {
      // const { preSignedUrl, preSignedUrlMp3, path } = await getAudioUploadUrl();
      let audioBlob: Blob | undefined;
      if (audioIsInFormat(file.path, ".mp3")) {
        const audioBlobMp3 = new Blob([file]);
        audioBlob = await convertMp3ToArm(audioBlobMp3);
      } else {
        audioBlob = new Blob([file]);
      }
      if (audioBlob) {
        const audioFile: any = await handleFileUpload(file)
        // await uploadAudioToS3(preSignedUrl, audioBlob);
        // await uploadAudioToS3(preSignedUrlMp3, audioBlob, true);
        await createAudioMessageTemplate(audioFile, name);
      } else {
        toast.error("Error to upload audio file");
      }
    } else {
      toast.error("Error to upload audio file");
    }
    setLoading(false);
    onClose();
    refreshTemplates && refreshTemplates();
  }
  async function onPressSendAudioFile() {
    await saveAudioFile();
    onClose();
  }

  async function onPressSendAudioTemplate() {
    setLoadingSendTemplate(true);
    try {
      if (deviceId) {
        if (audioTemplate && audioTemplate.filePath) {
          if (typeof deviceId === "number") {
            await sendAudioTemplate(deviceId, audioTemplate.filePath);
            toast.success("Message sent successfully");
          } else {
            const devicesIds = deviceId;
            await sendAudioTemplateForMultipleDevices(
              devicesIds,
              audioTemplate.filePath
            );
            toast.success("Message sent successfully");
          }
        } else {
          toast.error("Error to send audio");
        }
      }
      setLoadingSendTemplate(false);
      onClose();
    } catch (e) {
      setLoadingSendTemplate(false);
      HandleUIError(e, intercepError500);
    }
    onClose();
  }
  //TODO: remove this function when the problem are solved
  function intercepError500(statusCode: number) {
    if (statusCode === 500) {
      toast.success("Message sent successfully");
    }
  }

  return (
    <div>
      <>
        <div className="d-flex align-items-center">
          <p className="text-light fs-5 m-0 ms-3">Template name: </p>
          <Input
            className="mt-2 w-50 m-0 ms-3 text-light"
            onChange={onChangeName}
            autoFocus
          />
        </div>
      </>

      {audioTemplate ? (
        <div>
          <p className="fs-5 ms-3 mt-3 text-center">
            Template: {audioTemplate.name}
          </p>
          <div className="w-100 d-flex pt-3 justify-content-end">
            <Button
              onClick={onPressSendAudioTemplate}
              className="px-4 me-3 mt-5"
            >
              {loadingSendTemplate ? <Spinner /> : "Send"}
            </Button>
          </div>
        </div>
      ) : (
        <>
          {file ? (
            <div className="d-flex flex-column align-items-center mt-4">
              <p className="mb-4">File: {file.name}</p>
              <div className="d-flex w-100 justify-content-end pe-3">
                <Button className="px-4" onClick={onPressSendAudioFile}>
                  {loading ? <Spinner /> : "Save"}
                </Button>
              </div>
            </div>
          ) : (
            <>
              <div
                className={
                  "d-flex w-100 justify-content-center align-items-center py-5"
                }
              >
                <div className="d-flex flex-column align-items-center">
                  <DropZone onChangeFile={onChangeFile} />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default AudioRecorder;
