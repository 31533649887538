import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    otp: null,
    email: null
}


export const resetPassSlice = createSlice({
    name: "resetPass",
    initialState,
    reducers: {
        saveEmail: (state, action) => {
            state.email = action.payload
        },
        saveOtp: (state, action) => {
            state.otp = action.payload

        },
        clearVerification: (state, action) => {
            state.otp = null
            state.email = null

        }
    }
})

export const { saveEmail, saveOtp , clearVerification } = resetPassSlice.actions;
export default resetPassSlice.reducer