import * as React from "react";
import { cilPlus } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import "./Atomics.scss";

//components
import { Spinner } from ".";

type SizeIcon =
  | "xl"
  | "custom"
  | "custom-size"
  | "sm"
  | "lg"
  | "xxl"
  | "3xl"
  | "4xl"
  | "5xl"
  | "6xl"
  | "7xl"
  | "8xl"
  | "9xl"
  | undefined;

interface Props {
  onClick?: () => void;
  icon: typeof cilPlus;
  size?: SizeIcon;
  className?: string;
  isLoading?: boolean;
}

const IconButton: React.FC<Props> = ({
  onClick,
  icon,
  size,
  className,
  isLoading,
}) => {
  return (
    <>
      {isLoading ? (
        <Spinner className={`text-light icon-button-spinner ${className}`} />
      ) : (
        <CIcon
          icon={icon}
          size={size ? size : "xl"}
          className={`icon-button-animated ${className}`}
          onClick={onClick}
        />
      )}
    </>
  );
};

export default IconButton;
