import * as React from "react";
import "./SideBar.scss";
import {
  CSidebar,
  CSidebarNav,
  CSidebarHeader,
  CNavTitle,
  CNavItem,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilSpeedometer, cilDescription } from "@coreui/icons";
import { Link, useLocation } from "react-router-dom";

//images
import gcLogoHoriz from "../assets/gc_logo_powby_horiz_white.png";

interface Props {
  visible: boolean;
}

const SideBar: React.FC<Props> = ({ visible }) => {
  const selectedPathColorClass: string = "bg-dark4";
  const [pathName, setPathName] = React.useState<string>("");
  const location = useLocation();

  React.useEffect(
    function () {
      switch (location.pathname) {
        case "/":
          setPathName("Organizations");
          break;
        case "/pre-defined-messages":
          setPathName("Pre-defined messages");
          break;
        case "/category":
          setPathName("category");
          break;
        case "/section":
          setPathName("section");
          break;
        default:
          setPathName("");
          break;
      }
    },
    [location.pathname]
  );

  return (
    <>
      <CSidebar visible={visible} className="h-100">
        <CSidebarHeader className="logo">
          <img
            src={gcLogoHoriz}
            alt="GuardianCall powered by LutiBand"
            className="img-fluid"
          />
        </CSidebarHeader>
        <CSidebarNav className="bg-dark1 d-flex justify-content-between">
          <div>
            <Link to="/" className="text-decoration-none text-light">
              <div
                className={`d-flex pt-3 pb-3 ${
                  pathName === "Organizations" ? selectedPathColorClass : ""
                }`}
              >
                <CIcon icon={cilSpeedometer} className="me-3 ms-3 mt-1" />
                <p>Organizations</p>
              </div>
            </Link>
            <CNavTitle>OPTIONS</CNavTitle>
            <Link
              className="text-decoration-none text-light"
              to="pre-defined-messages"
            >
              <CNavItem
                href="#"
                className={
                  pathName === "Pre-defined-messages"
                    ? selectedPathColorClass
                    : ""
                }
              >
                <CIcon icon={cilDescription} className="me-3" />
                Pre-defined messages
              </CNavItem>
            </Link>
            <Link
                className="text-decoration-none text-light"
                to="category"
            >
              <CNavItem
                  href="#"
                  className={
                    pathName === "category"
                        ? selectedPathColorClass
                        : ""
                  }
              >
                <CIcon icon={cilDescription} className="me-3" />
                App Tour Category
              </CNavItem>
            </Link>
            <Link
                className="text-decoration-none text-light"
                to="section"
            >
              <CNavItem
                  href="#"
                  className={
                    pathName === "section"
                        ? selectedPathColorClass
                        : ""
                  }
              >
                <CIcon icon={cilDescription} className="me-3" />
                App Tour Section
              </CNavItem>
            </Link>
          </div>
        </CSidebarNav>
      </CSidebar>
    </>
  );
};

export default SideBar;
