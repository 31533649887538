import * as React from "react";

//components
import { MenuHeader } from "../atomics";
import { CSpinner } from "@coreui/react";
import { OrganizationRow } from "../Organizations";
import { OrganizationResponse } from "../../services/types";
import { OrgSelected } from "../../utils/types";
import AdminsList from "../Admins/AdminsList";
import confirmationDialog from "../toast-dialogs/ConfirmationDialog";
import HandleUIError from "../../utils/HandleUIError";
import { deleteOrganization } from "../../services/OrganizationsServices";

interface Props {
  organizations: OrganizationResponse[];
  isLoading: boolean;
  selectedOrg: OrgSelected | null | undefined;
  selectOrg: (org: OrgSelected) => void;
  handleStatus: (id: any, status: any) => void;
  handleDeleteOrganization: (id: any) => void;
  setPreviousAction: (val: boolean) => void;
  previousAction: boolean;
  loading: boolean
  refreshOrganizations: () => Promise<void>;
}

const OrganizationsList: React.FC<Props> = ({
  organizations,
  isLoading,
  selectedOrg,
  selectOrg,
  handleStatus,
  handleDeleteOrganization,
  setPreviousAction,
  previousAction,
  loading,
  refreshOrganizations
}) => {

  const [isLoadingDelete, setIsLoadingDelete] = React.useState(false);


  // async function deleteTemplate(id: any) {
  //   if (id) {
  //     setIsLoadingDelete(true);
  //     try {
  //       await deleteOrganization(id);
  //       await refreshOrganizations();
  //       setIsLoadingDelete(false);
  //     } catch (e) {
  //       setIsLoadingDelete(false);
  //       HandleUIError(e);
  //     }
  //   }
  // }

  // function onPressDelete() {
  //   confirmationDialog({
  //     title: "Are you sure you want to delete this template?",
  //     confirmAction: deleteTemplate,
  //   });
  // }

  return (
    <>
      {!selectedOrg ? (
        <>
          <MenuHeader className="d-flex pt-3 pb-3">
            <p className="text-color section-width-1 ms-4 d-none d-sm-block justify-content-center">
              S.No
            </p>
            <p className="text-color section-width-2 d-none d-sm-block justify-content-center">
              Name
            </p>
            <p className="text-color section-width-3 d-none d-sm-block justify-content-center">
              Address
            </p>
            <p className="text-color section-width-4 d-none d-sm-block justify-content-center">
              Drill mode
            </p>
            <p className="text-color section-width-4 d-none d-sm-block justify-content-center">
              Status
            </p>
            <p className="text-color section-width-5 d-none d-sm-block"></p>
          </MenuHeader>
          {!isLoading && organizations ? (
            organizations.map((organization, index: number) => (
              <OrganizationRow
                organization={organization}
                index={index}
                key={organization.id}
                selectOrg={selectOrg}
                handleStatus={handleStatus}
                handleDeleteOrganization={handleDeleteOrganization}
                setPreviousAction={setPreviousAction}
                previousAction={previousAction}
                loading={loading}
                refreshOrganizations={refreshOrganizations}

              />
            ))
          ) : (
            <div className="spinner mt-4">
              <CSpinner variant="grow" />
            </div>
          )}
        </>
      ) : (
        <AdminsList organization={selectedOrg} />
      )}
    </>
  );
};

export default OrganizationsList;
