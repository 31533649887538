import { combineReducers } from "redux";
// reducers
import UserSlice from "./userReducer";
import OrganizationSlice from "./organizationReducer";
import ResetPassSlice from "./resetPasswordReducer";

export default combineReducers({
  UserSlice,
  OrganizationSlice,
  resetPass: ResetPassSlice
});
