
import * as React from "react";
import HandleUIError from "../../utils/HandleUIError";
import toast from "react-hot-toast";

//components
import {
  Modal,
  Input,
  Button,
  Spinner,
  IconButton,
} from "../atomics";
import { cilX } from "@coreui/icons";
import { addUserAdmin } from "../../services/AdminServices";

interface Props {
  visible: boolean;
  closeModal: () => void;
  refreshAdmins: () => void;
  organizationId: number;
}

const AddAdmin: React.FC<Props> = ({ visible, closeModal, refreshAdmins, organizationId, }) => {
  const [firstName, setFirstName] = React.useState<string>("");
  const [lastName, setLastName] = React.useState<string>("");
  const [userEmail, setUserEmail] = React.useState<string>("");
  const [phone, setPhone] = React.useState<string>("");
  const [addUserLoading, setAddUserLoading] = React.useState<boolean>(false);
  const [password, setPassword] = React.useState<string>("");
  const [cpassword, setCpassword] = React.useState<string>("");
  const [passwordMatchError, setPasswordMatchError] = React.useState<string>("");

  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  const handleInputFirstName = (e: any) => {
    setFirstName(e.target.value);
  };

  const handleInputLastName = (e: any) => {
    setLastName(e.target.value);
  };

  const handleInputMail = (e: any) => {
    setUserEmail(e.target.value);
  };

  const handleInputPhone = (e: any) => {
    setPhone(e.target.value);
  };

  const handleInputPassword = (e: any) => {
    setPassword(e.target.value);
  };

  const handleInputConformedPassword = (e: any) => {
    setCpassword(e.target.value);
  };


  async function addUser(firstName: string, lastName: string, email: string, phone: string, password: string): Promise<void> {
    if (password !== cpassword) {
      setPasswordMatchError('Passwords do not match');
      return
    }
    if (!pattern.test(email)) {
      toast.error("Please enter valid email address")
      return

    }
    else {
      setPasswordMatchError('');
      setAddUserLoading(true);
      try {
        if (password === "" || password === null) {
          toast.error("Password cannot be empty")
          setAddUserLoading(false);
        } else if (email === "" || email === null) {
          toast.error("Email cannot be empty")
          setAddUserLoading(false);
        }

        else {
          let userData = {
            "firstName": firstName,
            "lastName": lastName,
            "email": email,
            "phone": phone,
            "password": password
          }
          const res = await addUserAdmin(organizationId, userData);
          console.log("res", res)
          await refreshAdmins();
          toast.success("Admin created succesfully");
          setAddUserLoading(false);
          onCloseModal();
        }
      } catch (e: any) {
        setAddUserLoading(false);
        const error = JSON.stringify(e)
        console.log("e", JSON.stringify(e))
        if (e.statusCode == 409) {

          toast.error("Duplicate Entry")
        }
        HandleUIError(e);
      }
    }
  }

  function onCloseModal() {
    setFirstName("")
    setLastName("")
    setUserEmail("")
    setPhone("")
    setPassword("")
    closeModal();
  }

  return (
    <Modal visible={visible} onPressOut={onCloseModal}>
      <div className="edit-user-container">
        <div className="edit-user-header">
          <p className="text-light py-2 ps-4 fs-5">Add admin</p>
          <IconButton icon={cilX} className="me-3" onClick={onCloseModal} />
        </div>

        <div className="users-container">
          <p className="text-light fs-5 ms-3 pt-3">
            First Name
          </p>
          <Input
            className="mt-2 mb-3 ms-3 search-phone-input"
            onChange={handleInputFirstName}
            autoFocus
          />
          <p className="text-light fs-5 ms-3 pt-3">
            Last Name
          </p>
          <Input
            className="mt-2 mb-3 ms-3 search-phone-input"
            onChange={handleInputLastName}
          />
          <p className="text-light fs-5 ms-3 pt-3">
            Email
          </p>
          <Input
            className="mt-2 mb-3 ms-3 search-phone-input"
            onChange={handleInputMail}
          />



          <p className="text-light fs-5 ms-3 pt-3">
            Phone
          </p>
          <Input
            type="number"
            className="mt-2 mb-3 ms-3 search-phone-input"
            onChange={handleInputPhone}
          />
          <p className="text-light fs-5 ms-3 pt-3">
            Password
          </p>
          <Input
            type="password"
            className="mt-2 mb-3 ms-3 search-phone-input"
            onChange={handleInputPassword}
          />
          <p className="text-light fs-5 ms-3 pt-3">
            Confirmed Password
            &nbsp;
            {passwordMatchError && <p className="text-danger m-0 d-inline-block small">{passwordMatchError}</p>}
          </p>
          <Input
            type="password"
            className="mt-2 ms-3 search-phone-input"
            onChange={handleInputConformedPassword}
          />
          <div className="d-flex justify-content-center">
            <Button
              disabled={addUserLoading}
              className="mt-7 button-add px-4 mb-3"
              onClick={() => {
                addUser(firstName, lastName, userEmail, phone, password)
              }}
            >
              {!addUserLoading ? "Add" : <Spinner />}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddAdmin;
