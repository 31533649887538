import BenzAMRRecorder from "benz-amr-recorder";
import HandleUIError from "./HandleUIError";
import { ContentState, convertFromHTML } from "draft-js";

const HIGHLIGHT_CHARACTER = "(#T#)";
const LINE_BREAK = "\n";

export function htmlToDeviceFormat(text: string) {
  let textAux = text;
  textAux = textAux.replace(/<p>/g, "");
  textAux = textAux.replace(/<\/p>/g, "");
  textAux = textAux.replace(/<br>/g, LINE_BREAK);
  // textAux = textAux.replace(/<br\/>/g, "");
  textAux = textAux.replace(/&nbsp;/g, "");
  textAux = textAux.replace(/<h3>/g, HIGHLIGHT_CHARACTER);
  textAux = textAux.replace(/<\/h3>/g, HIGHLIGHT_CHARACTER + " " + LINE_BREAK);
  return textAux;
}

export function deviceFormatToHtml(text: string) {
  let textAux = text;
  while (textAux.includes(HIGHLIGHT_CHARACTER)) {
    textAux = textAux.replace(HIGHLIGHT_CHARACTER, "<h3>");
    textAux = textAux.replace(HIGHLIGHT_CHARACTER + " " + LINE_BREAK, "</h3>");
  }
  textAux = textAux.replace(/\(#E#\)/g, "<br>");

  return textAux;
}

export function convertToStateFromHTML(text: string) {
  const blocksFromHTML = convertFromHTML(deviceFormatToHtml(text));
  console.log(deviceFormatToHtml(text));
  const state = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );
  return state;
}

export async function convertMp3ToArm(mediaBlobUrl: string | Blob) {
  try {
    const amrFromMp3 = new BenzAMRRecorder();
    if (typeof mediaBlobUrl === "string") {
      await amrFromMp3.initWithUrl(mediaBlobUrl);
    } else if (mediaBlobUrl instanceof Blob) {
      await amrFromMp3.initWithBlob(mediaBlobUrl);
    }
    return amrFromMp3.getBlob();
  } catch (e) {
    HandleUIError(e);
  }
}

export function audioIsInFormat(audioPath: string, format: string): boolean {
  const fileType = audioPath.substring(audioPath.lastIndexOf("."));
  return fileType === format;
}
