import * as React from "react";
import "./NewTemplate.scss";

//components
import { Modal, Button, Input, Spinner, IconButton } from "../atomics";
import { TextEditor } from ".";
import { cilX } from "@coreui/icons";
import { AudioRecorder } from ".";
import { GroupButtons, ButtonForGroup } from "../atomics/GroupButtons";

//services
import {
  createMessageTemplate,
  createEmailMessageTemplate,
} from "../../services/messagesService";
import HandleUIError from "../../utils/HandleUIError";

interface Props {
  visible: boolean;
  closeModal: () => void;
  refreshTemplates: () => Promise<void>;
}

type TypeOptions = "audio" | "text" | "email";

const NewTemplate: React.FC<Props> = ({
  visible,
  closeModal,
  refreshTemplates,
}) => {
  const [text, setText] = React.useState("");
  const [name, setName] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [templateType, setTemplateType] = React.useState<TypeOptions>("text");

  function onChangeText(t: string) {
    setText(t);
  }

  function onChangeName(e: any) {
    setName(e.target.value);
  }

  function onChangeTitle(e: any) {
    setTitle(e.target.value);
  }

  function onPressText() {
    setTemplateType("text");
  }

  function onPressAudio() {
    setTemplateType("audio");
  }

  function onPressEmail() {
    setTemplateType("email");
  }

  async function createTemplate() {
    setLoading(true);
    try {
      if (templateType === "text") {
        await createMessageTemplate(text, "custom", name, title);
      } else {
        await createEmailMessageTemplate(text, "custom", name, title);
      }
      await refreshTemplates();
      onPressClose();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      HandleUIError(e);
    }
  }

  function onPressClose() {
    closeModal();
  }

  return (
    <Modal visible={visible} onPressOut={onPressClose}>
      <div className="new-template-container">
        <div className="new-template-header d-flex justify-content-between align-items-center">
          <p className="text-light fs-5">New message template</p>
          <IconButton icon={cilX} onClick={onPressClose} />
        </div>
        <div className="d-flex justify-content-center py-3 mb-2">
          <GroupButtons>
            <ButtonForGroup
              label="Text"
              checked={templateType === "text"}
              onClick={onPressText}
            />
            <ButtonForGroup
              label="Audio"
              checked={templateType === "audio"}
              onClick={onPressAudio}
            />
            <ButtonForGroup
              label="Email"
              onClick={onPressEmail}
              checked={templateType === "email"}
            />
          </GroupButtons>
        </div>
        {templateType !== "audio" && (
          <>
            <div className="search-phone pb-2">
              <div className="px-3 pb-2">
                <div className="d-flex align-items-center mb-2">
                  <p className="text-light fs-5 m-0 me-2 template-name-width">
                    Template name:
                  </p>
                  <Input
                    className="text-light w-100"
                    onChange={onChangeName}
                    autoFocus
                  />
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <p className="text-light fs-5 m-0 me-2">
                    {templateType === "text" ? "Title:" : "Subject:"}
                  </p>
                  <Input
                    className="text-light  w-100"
                    onChange={onChangeTitle}
                  />
                </div>
              </div>
            </div>
            <TextEditor
              className=""
              onChangeText={onChangeText}
              isEmail={templateType === "email"}
            />
            <div className="d-flex justify-content-end me-3 mb-3">
              <Button
                onClick={createTemplate}
                disabled={loading}
                className="px-4"
              >
                {loading ? <Spinner /> : "Save"}
              </Button>
            </div>
          </>
        )}
        {templateType === "audio" && (
          <AudioRecorder
            closeModal={onPressClose}
            refreshTemplates={refreshTemplates}
          />
        )}
      </div>
    </Modal>
  );
};

export default NewTemplate;
