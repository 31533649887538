import axiosConfig from "./axiosConfig";
import { AxiosError, AxiosResponse } from "axios";
import ApiError from "../utils/ApiError";
import { User, UserData } from './types';

export async function getAdmins(
  organizationId: number,
  limit: number,
  page: number,
  order: "DECS",
  title: ""
): Promise<{ data: User[]; total: number }> {
  try {
    const response = await axiosConfig.get(
      `/organizations/${organizationId}/users?limit=${limit}&page=${page}&order=${order}&title=${title}`
    );
    const data = response.data.data.rows as Array<User>;
    return { data, total: response.data.data.count, };
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function addUserAdmin(
  organizationId: number,
  userData: UserData
): Promise<void> {
  try {
    return await axiosConfig.post(`/organizations/${organizationId}/users`, {
      "firstName": userData.firstName,
      "lastName": userData.lastName,
      "email": userData.email,
      "phone": userData.phone,
      "password": userData.password
    });
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function updatedAdmin(
  organizationId: number,
  userId: number,
  userData: UserData
): Promise<void> {
  try {
    await axiosConfig.patch(
      `/organizations/${organizationId}/users/${userId}`,
      {
        "firstName": userData.firstName,
        "lastName": userData.lastName,
        "email": userData.email,
        "phone": userData.phone,
        "password": userData.password
      }
    )
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function updateAdminStatus(
  userId: number,
  status: boolean

): Promise<void> {
  try {
    const body = {
      status: status
    };
    await axiosConfig.put(`/organizations/${userId}/users/${userId}`, body);
  } catch (e) {
    const err = e as Error | AxiosError;
    throw new ApiError(err);
  }
}

export async function deleteAdmin(organizationId: number, userId: number): Promise<void> {
  try {
    await axiosConfig.delete<AxiosResponse>(`/organizations/${organizationId}/users/${userId}`)
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err)
  }
}
